<script>
/***
 Name: edit_table
 Code: sa0ChunLuyu
 Time: 2021/11/10 16:53
 Remark: 编辑面板
 */
import EditItem from './edit_item.vue'
import EditDir from "./edit_dir.vue";

export default {
  props: ['reload'],
  components: {EditItem, EditDir},
  data() {
    return {
      item_info: false,
      nengli_id: 0,
      item_type: 0,
      create_do: 1
    }
  },
  watch: {
    $route: {
      handler() {
        this.mountedDo()
      },
      deep: true
    }
  },
  mounted() {
    this.mountedDo()
  },
  methods: {
    mountedDo() {
      this.nengli_id = 0
      this.nengli_id = Number(this.$route.params.id ? this.$route.params.id : 0)
      this.item_type = 0
      if (this.nengli_id !== 0) {
        this.getNengLiInfo()
      }
    },
    getNengLiInfo() {
      this.$sa0.post({
        url: this.$api('根据Id获取能力详情'),
        data: {
          id: this.nengli_id
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            let info = response.data.bind_sample;
            this.item_info = info
            this.item_type = info.type
            this.create_do = 0
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    toBack() {
      this.create_do = 0
      this.item_type = this.item_info.type
    },
    createDir() {
      this.create_do = 1
      this.item_type = 0
    },
    createItem() {
      this.create_do = 1
      this.item_type = 1
    },
    delThis(){
      layer.confirm('是否删除？', {
        btn: ['确定', '取消']
      }, () => {
        this.$sa0.post({
          url: this.$api('删除检测能力库'),
          data: {
            id: this.nengli_id
          }
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              layer.closeAll()
              this.$router.push({
                params: {
                  id: this.item_info.pid
                }
              })
            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })
      }, () => {
      });
    },
  }
}
</script>
<template>
  <div class="edit_table_wrapper">
    <div v-if="create_do === 1 && nengli_id !== 0" class="float-left button_wrapper">
      <a-space>
        <button @click="toBack()" class="layui-btn layui-btn-primary">返回</button>
      </a-space>
    </div>
    <div v-if="create_do === 0 && item_type === 0" class="float-left button_wrapper">
      <a-space>
        <button @click="createDir()" class="layui-btn layui-btn-primary">添加分类</button>
        <button @click="createItem()" class="layui-btn layui-btn-normal">添加检测能力</button>
        <button @click="delThis()" class="layui-btn layui-btn-danger">删除</button>
      </a-space>
    </div>
    <div v-if="create_do === 0 && item_type === 1" class="float-left button_wrapper">
      <a-space>
        <button @click="delThis()" class="layui-btn layui-btn-danger">删除</button>
      </a-space>
    </div>
    <EditItem v-if="item_type === 1" :create_do="create_do" :reload="reload"></EditItem>
    <EditDir v-if="item_type === 0" :create_do="create_do" :reload="reload"></EditDir>
  </div>
</template>
<style scoped>
.button_wrapper {
  position: absolute;
  width: 1000px;
  text-align: right;
}

.edit_table_wrapper {
  position: absolute;
  left: 280px;
  right: 10px;
  bottom: 0;
  top: 0;
}
</style>
