<script>/***
 Name: edit_dir
 Code: sa0ChunLuyu
 Time: 2021/11/10 16:53
 Remark: 编辑文件夹
 */
import ZiZhi from "./zizhi/zizhi";

export default {
  components: {ZiZhi},
  props: ['create_do', 'reload'],
  data() {
    return {
      danwei_list: [],
      gongshi_list: [],
      nl_type_list: [],
      nl_type_choose: undefined,
      fangfa_list: [],
      yinzi_arr: [],
      fangfa_choose: [],
      xiangmu_list: [],
      item_do: false,
      xiangmu_info: false,
      xiangmu_choose: [],
      yp_type_list: [],
      yp_type_choose: [],
      ry_zizhi_guanli: true,
      nengli_info: {
        "bindc_code": "",
        "pid": "0",
        "type": "1",
        "sample_category_id": "0",
        "det_item_id": "0",
        "bindsample_factor": [],
        "det_method_id": "0",
        "sample_group_id": "0",
        "bind_remark": "",
        "is_qualify": "2"
      }
    }
  },
  watch: {
    xiangmu_choose() {
      if (this.item_do) this.getXiangmuInfo()
    },
    $route: {
      handler() {
        this.getItemInfo()
      },
      deep: true
    },
    create_do() {
      this.getItemInfo()
    }
  },
  mounted() {
    this.getItemInfo()
  },
  methods: {
    getXiangmuInfo(do_true = true) {
      if (this.xiangmu_choose.length === 0) return;
      let xiangmu_id = this.xiangmu_choose[this.xiangmu_choose.length - 1]
      this.$sa0.post({
        url: this.$api('根据id获取检测项目详情'),
        data: {
          id: xiangmu_id
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.xiangmu_info = response.data.det_item
            if (do_true) {
              if (this.xiangmu_info.factor_type === 1) {
                this.yinzi_arr = [{
                  factor_id: this.xiangmu_info.id,
                  factor_name: this.xiangmu_info.name,
                  factor_type: 1,
                  factor_value: [0, 0, 0],
                }]
              } else {
                console.log(this.xiangmu_info.factor)
                this.yinzi_arr = this.xiangmu_info.factor.map((item) => {
                  return {
                    factor_id: item.id,
                    factor_name: item.name,
                    factor_type: 1,
                    factor_value: [0, 0, 0, undefined],
                  }
                })
              }
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    thisReload() {
      this.getItemInfo()
      this.reload()
    },
    getGongShiList() {
      this.$sa0.post({
        url: this.$api('公式列表'),
        data: {
          id: this.$route.params.id
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.gongshi_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getItemInfo() {
      this.getYpList()
      this.getItemList()
      this.getFuncList()
      this.getNengLiTypeList()
      this.getGongShiList()
      this.getDanweiList()
      if (this.create_do === 1) {
        this.$sa0.post({
          url: this.$api('根据Id获取能力详情'),
          data: {
            id: this.$route.params.id
          }
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              this.nengli_info = {
                id: 0,
                pid: response.data.bind_sample.id,
                bindc_code: ''
              }
            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })
      } else {
        if (Number(this.$route.params.id) !== 0) {
          this.$sa0.post({
            url: this.$api('根据Id获取能力详情'),
            data: {
              id: this.$route.params.id
            }
          }).then((response) => {
            this.$sa0.response({
              response: response.data,
              then: (response) => {
                this.nengli_info = response.data.bind_sample
                this.yp_type_choose = (this.nengli_info.sample_category_ids.toString()).split(',').map((i) => {
                  return Number(i)
                })
                this.xiangmu_choose = (this.nengli_info.det_item_ids.toString()).split(',').map((i) => {
                  return Number(i)
                })
                this.fangfa_choose = ((this.nengli_info.det_method_ids?this.nengli_info.det_method_ids:'').toString()).split(',').map((i) => {
                  return Number(i)
                })
                this.nl_type_choose = this.nengli_info.sample_group_id
                let yinzi_arr = this.nengli_info.bindsample_factor.map((item) => {
                  item.factor_value = JSON.parse(item.factor_value)
                  return item
                })
                this.yinzi_arr = yinzi_arr
                this.getXiangmuInfo(false)
                setTimeout(() => {
                  this.item_do = true
                })
              },
              error: (response) => {
                layer.msg(response.message)
              },
            })
          })
        } else {
          this.nengli_info = {
            id: 0,
            pid: 0,
            bindc_code: ''
          }
        }
      }
    },
    updateDo() {
      if (this.yp_type_choose.length === 0) return layer.msg('请选择样品类型')
      if (this.xiangmu_choose.length === 0) return layer.msg('请选择检测项目')
      if (this.fangfa_choose.length === 0) return layer.msg('请选择检测方法')
      if (!this.nl_type_choose) return layer.msg('请选择检测能力分组')
      if (!this.nengli_info.bindc_code || this.nengli_info.bindc_code === '') return layer.msg('请输入检测能力名称')
      let for_msg = '';
      this.yinzi_arr.map((item, key) => {
        if (item.factor_type === 3) {
          if (!item.factor_value[0] || item.factor_value[0] === 0) {
            for_msg = `请选择第${key + 1}个因子的公式`
          }
        }
        if (!item.factor_value[item.factor_value.length - 1] || item.factor_value[item.factor_value.length - 1] === 0) {
          for_msg = `请选择第${key + 1}个因子的单位`
        }
      })
      if (for_msg !== '') return layer.msg(for_msg)
      let data = {
        id: this.nengli_info.id,
        bindc_code: this.nengli_info.bindc_code,
        pid: this.nengli_info.pid,
        type: "1",
        sample_category_id: this.yp_type_choose[this.yp_type_choose.length - 1],
        det_item_id: this.xiangmu_choose[this.xiangmu_choose.length - 1],
        bindsample_factor: JSON.stringify(this.yinzi_arr),
        det_method_id: this.fangfa_choose[this.fangfa_choose.length - 1],
        sample_group_id: this.nl_type_choose,
        bind_remark: this.nengli_info.bind_remark,
        is_qualify: this.nengli_info.is_qualify
      }
      this.$sa0.post({
        url: this.$api('修改检测能力库'),
        data: data
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg('修改成功')
            this.thisReload()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getYpList() {
      this.$sa0.post({
        url: this.$api('获取样品类型'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.yp_type_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getItemList() {
      this.$sa0.post({
        url: this.$api('获取检测项目分级列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.xiangmu_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getFuncList() {
      this.$sa0.post({
        url: this.$api('检测方法分级列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.fangfa_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getNengLiTypeList() {
      this.$sa0.post({
        url: this.$api('获取基础字段全部列表'),
        data: {
          type: 2
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.nl_type_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getDanweiList() {
      this.$sa0.post({
        url: this.$api('获取基础字段全部列表'),
        data: {
          type: 5
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.danwei_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    ryZizhiGuanli() {
      this.ry_zizhi_guanli = true
    },
    createDo() {
      if (this.yp_type_choose.length === 0) return layer.msg('请选择样品类型')
      if (this.xiangmu_choose.length === 0) return layer.msg('请选择检测项目')
      if (this.fangfa_choose.length === 0) return layer.msg('请选择检测方法')
      if (!this.nl_type_choose) return layer.msg('请选择检测能力分组')
      if (!this.nengli_info.bindc_code || this.nengli_info.bindc_code === '') return layer.msg('请输入检测能力名称')
      let for_msg = '';
      this.yinzi_arr.map((item, key) => {
        if (item.factor_type === 3) {
          if (!item.factor_value[0] || item.factor_value[0] === 0) {
            for_msg = `请选择第${key + 1}个因子的公式`
          }
        }
        if (!item.factor_value[item.factor_value.length - 1] || item.factor_value[item.factor_value.length - 1] === 0) {
          for_msg = `请选择第${key + 1}个因子的单位`
        }
      })
      if (for_msg !== '') return layer.msg(for_msg)
      let data = {
        bindc_code: this.nengli_info.bindc_code,
        pid: this.nengli_info.pid,
        type: "1",
        sample_category_id: this.yp_type_choose[this.yp_type_choose.length - 1],
        det_item_id: this.xiangmu_choose[this.xiangmu_choose.length - 1],
        bindsample_factor: JSON.stringify(this.yinzi_arr),
        det_method_id: this.fangfa_choose[this.fangfa_choose.length - 1],
        sample_group_id: this.nl_type_choose,
        bind_remark: this.nengli_info.bind_remark,
        is_qualify: "2"
      }
      this.$sa0.post({
        url: this.$api('创建检测能力库'),
        data: data
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg('创建成功')
            this.$router.push({
              params: {
                id: response.data.id
              }
            })
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    factorTypeChange(e, key) {
      switch (e) {
        case 1:
          this.$set(this.yinzi_arr[key], 'factor_value', [0, 0, 0, this.danwei_list.length === 0 ? undefined : this.danwei_list[0].id])
          break;
        case 2:
          this.$set(this.yinzi_arr[key], 'factor_value', [0, 0, 0, 0, this.danwei_list.length === 0 ? undefined : this.danwei_list[0].id])
          break;
        case 3:
          this.$set(this.yinzi_arr[key], 'factor_value', [
            this.gongshi_list.length === 0 ? undefined : this.gongshi_list[0].id,
            this.danwei_list.length === 0 ? undefined : this.danwei_list[0].id,
          ])
          break;
      }
    },
    onZizhiClose() {
      this.ry_zizhi_guanli = false
    },
    saveClick() {
      if (this.create_do) {
        this.createDo();
      } else {
        this.updateDo();
      }
    },
  }
}
</script>
<template>
  <div>
    <ZiZhi v-if="ry_zizhi_guanli" :id="nengli_info.id" :close="onZizhiClose"></ZiZhi>
    <div class="edit_dir_wrapper">
      <div class="input_table_title_wrapper"><b>{{ create_do === 0 ? '编辑' : '新建' }}检测能力分类</b></div>
      <div class="mt-6">
        <div class="mt-3">
          <a-space>
            <div class="input_title_wrapper"><b>检测能力名称：</b></div>
            <div>
              <a-input v-model="nengli_info.bindc_code" class="input_wrapper"></a-input>
            </div>
          </a-space>
        </div>
        <div class="mt-3">
          <a-space>
            <div class="input_title_wrapper"><b>样品类型：</b></div>
            <div>
              <a-cascader :options="yp_type_list" :fieldNames="{
                label: 'name',
                value: 'id',
                children: 'children' }"
                          placeholder="选择样品类型"
                          style="width: 300px;"
                          v-model="yp_type_choose"/>
            </div>
          </a-space>
        </div>
        <div class="mt-3">
          <a-space>
            <div class="input_title_wrapper"><b>检测项目：</b></div>
            <div>
              <a-cascader :options="xiangmu_list" :fieldNames="{
                label: 'name',
                value: 'id',
                children: 'children' }"
                          placeholder="选择检测项目"
                          style="width: 300px;"
                          v-model="xiangmu_choose"/>
            </div>
          </a-space>
        </div>
        <div v-if="xiangmu_info" class="mt-3">
          <div v-for="(item,key) in yinzi_arr" class="mt-3">
            <div>
              <a-space>
                <div style="width: 750px;">
                  <a-space>
                    <div class="input_title_wrapper"><b>{{ item.factor_name }}</b></div>
                    <div>
                      <a-select v-model="item.factor_type" style="width:70px" placeholder="选择数据类型" @change="(e)=>{
                  factorTypeChange(e,key)
                }">
                        <a-select-option :value="1">数值</a-select-option>
                        <a-select-option :value="2">布尔</a-select-option>
                        <a-select-option :value="3">公式</a-select-option>
                      </a-select>
                    </div>
                    <div>
                      <div v-if="item.factor_type === 1">
                        <!--                  数值-->
                        <a-space>
                          <div style="width: 50px;">预定值</div>
                          <div>
                            <a-input style="width:100px" v-model="item.factor_value[0]"></a-input>
                          </div>
                          <div>上限</div>
                          <div>
                            <a-input style="width:100px" v-model="item.factor_value[1]"></a-input>
                          </div>
                          <div>下限</div>
                          <div>
                            <a-input style="width:100px" v-model="item.factor_value[2]"></a-input>
                          </div>
                        </a-space>
                      </div>
                      <div v-if="item.factor_type === 2">
                        <!--                  布尔-->
                        <a-space>
                          <div style="width: 50px;">预定值</div>
                          <div>
                            <a-input style="width:100px" v-model="item.factor_value[0]"></a-input>
                          </div>
                          <div>大于</div>
                          <div>
                            <a-select :default-value="0" v-model="item.factor_value[1]" style="width:70px">
                              <a-select-option :value="0">否</a-select-option>
                              <a-select-option :value="1">是</a-select-option>
                            </a-select>
                          </div>
                          <div>等于</div>
                          <div>
                            <a-select :default-value="0" v-model="item.factor_value[2]" style="width:70px">
                              <a-select-option :value="0">否</a-select-option>
                              <a-select-option :value="1">是</a-select-option>
                            </a-select>
                          </div>
                          <div>小于</div>
                          <div>
                            <a-select :default-value="0" v-model="item.factor_value[3]" style="width:70px">
                              <a-select-option :value="0">否</a-select-option>
                              <a-select-option :value="1">是</a-select-option>
                            </a-select>
                          </div>
                        </a-space>
                      </div>
                      <div v-if="item.factor_type === 3">
                        <!--                  公式-->
                        <a-space>
                          <div style="width: 50px;">公式</div>
                          <div>
                            <a-select v-model="item.factor_value[0]" style="width:100px">
                              <a-select-option :value="i.id" v-for="(i,k) in gongshi_list">{{
                                  i.name
                                }}
                              </a-select-option>
                            </a-select>
                          </div>
                        </a-space>
                      </div>
                    </div>
                  </a-space>
                </div>
                <div>
                  单位
                </div>
                <div>
                  <a-select default-value="danwei_list[0].id" v-model="item.factor_value[item.factor_value.length - 1]"
                            style="width:100px">
                    <a-select-option :value="i.id" v-for="(i,k) in danwei_list">{{
                        i.name
                      }}
                    </a-select-option>
                  </a-select>
                </div>
              </a-space>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <a-space>
            <div class="input_title_wrapper"><b>检测方法：</b></div>
            <div>
              <a-cascader :options="fangfa_list" :fieldNames="{
                label: 'name',
                value: 'id',
                children: 'children' }"
                          placeholder="选择检测方法"
                          style="width: 300px;"
                          v-model="fangfa_choose"/>
            </div>
          </a-space>
        </div>
        <div class="mt-3">
          <a-space>
            <div class="input_title_wrapper"><b>所属检测能力分组：</b></div>
            <div>
              <a-select v-model="nl_type_choose" style="width: 300px" placeholder="选择检测能力分组">
                <a-select-option v-for="(i,k) in nl_type_list" :value="i.id">
                  {{ i.name }}
                </a-select-option>
              </a-select>
            </div>
          </a-space>
        </div>
        <div class="mt-3" v-if="!create_do">
          <a-space>
            <div class="input_title_wrapper"><b>检测能力需要资质：</b></div>
            <div>
              <a-select v-model="nengli_info.is_qualify" style="width: 300px" placeholder="需要资质">
                <a-select-option :value="1">需要</a-select-option>
                <a-select-option :value="2">不需要</a-select-option>
              </a-select>
            </div>
          </a-space>
        </div>
        <div class="mt-3" v-if="!create_do && nengli_info.is_qualify === 1">
          <a-space>
            <div class="input_title_wrapper"></div>
            <a-button @click="ryZizhiGuanli()">人员资质管理</a-button>
          </a-space>
        </div>
        <div class="mt-3">
          <a-space>
            <div class="input_title_wrapper"><b>备注：</b></div>
            <div>
              <a-input v-model="nengli_info.bind_remark" class="input_wrapper"></a-input>
            </div>
          </a-space>
        </div>
        <div class="mt-3">
          <a-space>
            <div class="input_title_wrapper"></div>
            <div>
              <button @click="saveClick()" class="layui-btn layui-btn-normal">{{
                  create_do === 0 ? '保存' : '新建'
                }}
              </button>
            </div>
          </a-space>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.input_title_wrapper {
  width: 130px;
  text-align: right;
}

.input_wrapper {
  width: 300px;
}

.input_table_title_wrapper {
  font-size: 20px;
}
</style>
