<script>
/***
 Name: edit_dir
 Code: sa0ChunLuyu
 Time: 2021/11/10 16:53
 Remark: 编辑文件夹
 */
export default {
  props: ['create_do', 'reload'],
  data() {
    return {
      nengli_info: {
        id: 0,
        pid: 0,
        name: ''
      }
    }
  },
  watch: {
    $route: {
      handler() {
        this.getItemInfo()
      },
      deep: true
    },
    create_do() {
      this.getItemInfo()
    }
  },
  mounted() {
    this.getItemInfo()
  },
  methods: {
    thisReload() {
      this.getItemInfo()
      this.reload()
    },
    getItemInfo() {
      if (this.create_do === 1) {
        this.$sa0.post({
          url: this.$api('根据Id获取能力详情'),
          data: {
            id: this.$route.params.id
          }
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              this.nengli_info = {
                id: 0,
                pid: response.data.bind_sample.id,
                name: ''
              }
            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })
      } else {
        if (Number(this.$route.params.id) !== 0) {
          this.$sa0.post({
            url: this.$api('根据Id获取能力详情'),
            data: {
              id: this.$route.params.id
            }
          }).then((response) => {
            this.$sa0.response({
              response: response.data,
              then: (response) => {
                this.nengli_info = response.data.bind_sample
              },
              error: (response) => {
                layer.msg(response.message)
              },
            })
          })
        } else {
          this.nengli_info = {
            id: 0,
            pid: 0,
            name: ''
          }
        }
      }
    },
    updateDo() {
      this.$sa0.post({
        url: this.$api('修改检测能力库'),
        data: {
          id: this.nengli_info.id,
          bindc_code: this.nengli_info.bindc_code,
          pid: this.nengli_info.pid,
          type: "0",
          sample_category_id: "0",
          det_item_id: "0",
          bindsample_factor: "[]",
          det_method_id: "0",
          sample_group_id: "0",
          bind_remark: "",
          is_qualify: "2"
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg('修改成功')
            this.thisReload()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    createDo() {
      this.$sa0.post({
        url: this.$api('创建检测能力库'),
        data: {
          bindc_code: this.nengli_info.bindc_code,
          pid: this.nengli_info.pid,
          type: "0",
          sample_category_id: "0",
          det_item_id: "0",
          bindsample_factor: "[]",
          det_method_id: "0",
          sample_group_id: "0",
          bind_remark: "",
          is_qualify: "2"
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg('创建成功')
            this.$router.push({
              params: {
                id: response.data.id
              }
            })
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    saveClick() {
      if (this.create_do) {
        this.createDo();
      } else {
        this.updateDo();
      }
    },
  }
}
</script>
<template>
  <div>
    <div class="edit_dir_wrapper">
      <div class="input_table_title_wrapper"><b>{{ create_do === 0 ? '编辑' : '新建' }}检测能力分类</b></div>
      <div class="mt-6">
        <div class="mt-3">
          <a-space>
            <div class="input_title_wrapper"><b>分类名称：</b></div>
            <div>
              <a-input v-model="nengli_info.bindc_code" class="input_wrapper"></a-input>
            </div>
          </a-space>
        </div>
        <div class="mt-3">
          <a-space>
            <div class="input_title_wrapper"></div>
            <div>
              <button @click="saveClick()" class="layui-btn layui-btn-normal">{{
                  create_do === 0 ? '保存' : '新建'
                }}
              </button>
            </div>
          </a-space>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.input_title_wrapper {
  width: 120px;
  text-align: right;
}

.input_wrapper {
  width: 300px;
}

.input_table_title_wrapper {
  font-size: 20px;
}
</style>
