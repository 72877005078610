<template>
    <div>
        <a-drawer title="人员资质管理" :visible="visible" width="1200" @close="onClose">
            <div v-if="dataSource">
                <a-transfer class="tree-transfer" :data-source="dataSource" :target-keys="targetKeys" :render="item => item.title" :show-select-all="false" @change="onChange">
                    <template slot="children" slot-scope="{ props: { direction, selectedKeys }, on: { itemSelect } }">
                        <a-tree v-if="direction === 'left'" :replaceFields="{children:'child', title:'name', key:'id' }" blockNode checkable checkStrictly defaultExpandAll :checkedKeys="[...selectedKeys, ...targetKeys]" :treeData="treeData1" @check="
            (_, props) => {
              onChecked(_, props, [...selectedKeys, ...targetKeys], itemSelect);
            }
          " @select="
            (_, props) => {
              onChecked(_, props, [...selectedKeys, ...targetKeys], itemSelect);
            }
          "/>
                    </template>
                </a-transfer>
            </div>
        </a-drawer>
    </div>
</template>

<script>
    // function
    //
    // function

    export default {
        props: ['id', 'close'],
        data() {
            return {
                treeData1: [],
                visible: true,
                targetKeys: [],
                dataSource: [],
                transferDataSource: []
            }
        },
        name: "zizhi",
        computed: {
            treeData() {
                if (this.treeData1.length > 0) {
                    return this.handleTreeData(this.treeData1, this.targetKeys);
                } else {
                    return false
                }
            },
        },
        methods: {
            isChecked(selectedKeys, eventKey) {
                return selectedKeys.indexOf(eventKey) !== -1;
            },
            handleTreeData(data, targetKeys = []) {
                data.forEach(item => {
                    item['disabled'] = targetKeys.includes(item.key);
                    if (item.child) {
                        this.handleTreeData(item.child, targetKeys);
                    }
                });
                return data;
            },
            flatten(list = []) {
                let this_ = this
                list.forEach(item => {
                    this.transferDataSource.push(item);
                    this_.flatten(item.child);
                });
                this.dataSource = this.transferDataSource
            },
            getAllList() {
                this.$sa0.post({
                    url: this.$api('获取部门人员分级列表')
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.treeData1 = response.data.list
                            this.flatten(this.treeData1);
                        },
                        error: (response) => {
                        },
                    })
                })
            },
            onChange(targetKeys) {
                console.log('targetKeys----------------------------------------', targetKeys);
                this.targetKeys = targetKeys;
            },
            onChecked(_, e, checkedKeys, itemSelect) {
                const {eventKey} = e.node;
                itemSelect(eventKey, !this.isChecked(checkedKeys, eventKey));
            },
            onClose() {
                this.close()
            }
        },
        mounted() {
            console.log(this.id)
            this.getAllList()
        }
    }
</script>

<style scoped>

</style>
