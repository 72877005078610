<script>
/***
 Name: left_nav
 Code: sa0ChunLuyu
 Time: 2021/11/10 11:49
 Remark:
 */
export default {
  data() {
    return {
      expandedKeys: [],
      node_list: [],
      nav_id: 0,
    }
  },
  watch: {
    $route: {
      handler() {
        this.mountedDo()
      },
      deep: true
    }
  },
  mounted() {
    this.mountedDo()
  },
  methods: {
    navClick(ids) {
      let id = ids.length > 0 ? ids[0] : this.nav_id
      if (Number(id) === Number(this.$route.params.id)) {
        this.mountedDo()
      } else {
        this.$router.push({
          params: {
            id: id
          }
        })
      }
    },
    createClick() {
      this.navClick([0])
    },
    mountedDo() {
      this.nav_id = Number(this.$route.params.id ? this.$route.params.id : 0)
      this.getNengLiList();
    },
    getNengLiInfo() {
      this.$sa0.post({
        url: this.$api('根据Id获取能力详情'),
        data: {
          id: this.nav_id
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            let info = response.data.bind_sample;
            let k = (info.bind_sample_ids).toString().split(',').map((i) => {
              return Number(i)
            })
            k.push(this.nav_id)
            this.expandedKeys = k
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getNengLiList() {
      this.$sa0.post({
        url: this.$api('获取检测能力库所有分级列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.node_list = response.data.list;
            if (this.nav_id !== 0) this.getNengLiInfo()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
  }
}
</script>
<template>
  <div>
    <div class="nav_wrapper">
      <div class="samplelefttit">
        <div class="ml-3">检测能力</div>
      </div>
      <div>
        <div class="nav_main_wrapper">
          <a-tree :tree-data="node_list" :expandedKeys.sync="expandedKeys"
                  :replaceFields="{children:'child', title:'bindc_code', key:'id' }"
                  :show-line="true" @select="navClick">
            <a-icon slot="icon" type="carry-out"/>
          </a-tree>
        </div>
        <div class="nav_button_wrapper">
          <button @click="createClick()" class=" clb w-full">添加分类</button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.nav_button_wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.nav_main_wrapper {
  position: absolute;
  bottom: 41px;
  top: 40px;
  left: 0;
  right: 0;
  overflow-y: auto;
}
.nav_wrapper {
  border: 1px #5a5e6620 solid;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 250px;
}
.samplelefttit {
  background: #f9f9f9;
  line-height: 40px;
  border-bottom: 1px solid #eeeeee;
  font-weight: bold
}
  .clb{ background: #1e9fff; padding: 10px; color:#ffffff}
</style>
